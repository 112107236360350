// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-akademia-beomni-e-commerce-js": () => import("./../../../src/pages/akademia-beomni-e-commerce.js" /* webpackChunkName: "component---src-pages-akademia-beomni-e-commerce-js" */),
  "component---src-pages-ebook-index-js": () => import("./../../../src/pages/ebook/index.js" /* webpackChunkName: "component---src-pages-ebook-index-js" */),
  "component---src-pages-email-js": () => import("./../../../src/pages/email.js" /* webpackChunkName: "component---src-pages-email-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kariera-index-js": () => import("./../../../src/pages/kariera/index.js" /* webpackChunkName: "component---src-pages-kariera-index-js" */),
  "component---src-pages-kontakt-dziekujemy-js": () => import("./../../../src/pages/kontakt/dziekujemy.js" /* webpackChunkName: "component---src-pages-kontakt-dziekujemy-js" */),
  "component---src-pages-kontakt-index-js": () => import("./../../../src/pages/kontakt/index.js" /* webpackChunkName: "component---src-pages-kontakt-index-js" */),
  "component---src-pages-o-nas-index-js": () => import("./../../../src/pages/o-nas/index.js" /* webpackChunkName: "component---src-pages-o-nas-index-js" */),
  "component---src-pages-polityka-prywatnosci-index-js": () => import("./../../../src/pages/polityka-prywatnosci/index.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-index-js" */),
  "component---src-pages-tagi-index-js": () => import("./../../../src/pages/tagi/index.js" /* webpackChunkName: "component---src-pages-tagi-index-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-offer-js": () => import("./../../../src/templates/offer.js" /* webpackChunkName: "component---src-templates-offer-js" */),
  "component---src-templates-offer-page-js": () => import("./../../../src/templates/offer-page.js" /* webpackChunkName: "component---src-templates-offer-page-js" */),
  "component---src-templates-offer-post-js": () => import("./../../../src/templates/offer-post.js" /* webpackChunkName: "component---src-templates-offer-post-js" */),
  "component---src-templates-partner-post-js": () => import("./../../../src/templates/partner-post.js" /* webpackChunkName: "component---src-templates-partner-post-js" */),
  "component---src-templates-partners-js": () => import("./../../../src/templates/partners.js" /* webpackChunkName: "component---src-templates-partners-js" */),
  "component---src-templates-portfolio-cat-js": () => import("./../../../src/templates/portfolio-cat.js" /* webpackChunkName: "component---src-templates-portfolio-cat-js" */),
  "component---src-templates-portfolio-js": () => import("./../../../src/templates/portfolio.js" /* webpackChunkName: "component---src-templates-portfolio-js" */),
  "component---src-templates-portfolio-post-js": () => import("./../../../src/templates/portfolio-post.js" /* webpackChunkName: "component---src-templates-portfolio-post-js" */),
  "component---src-templates-tagi-js": () => import("./../../../src/templates/tagi.js" /* webpackChunkName: "component---src-templates-tagi-js" */)
}

